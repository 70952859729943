<template>
  <div class="contact">
    <h2>KONTAKT</h2>
    <div class="contact-container">
      <h3>Lukas Schlaffke | Leipzig</h3>
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
      </svg>
      <a href="mailto:post@luftlaeufer.de?subject=Hallo.">post@luftlaeufer.de</a>
      <br />
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
      </svg>
      <a href="tel:01733987212">0173 3 987 212</a>
      <div class="impressum-toggle" @click.prevent="openImprint">
        <a class="contact-container-impressum" :class="{ 'h2-open': impressumOpen }">Impressum</a>
        <svg v-if="impressumOpen" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </div>
      <div class="impressum" v-if="impressumOpen">
        <div v-if="imprintData !== null" v-html="imprintData.content.rendered"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      impressumOpen: false,
      imprintData: null,
    }
  },
  methods: {
    async openImprint() {
      let rawData = await fetch(`${process.env.VUE_APP_API_ROUTE}/wp-json/wp/v2/pages/91`)
      this.imprintData = await rawData.json()
      this.impressumOpen = !this.impressumOpen

      if ((await this.imprintData) !== null) {
        window.scrollBy(0, 200)
      }
    },
  },
}
</script>

<style lang="scss">
.contact {
  h2 {
    margin-left: 40%;
    font-size: 60px;

    @include break(1280px) {
      font-size: 40px !important;
    }

    @include break(750px) {
      margin-left: 10px !important;
      font-size: 36px !important;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;

    @include break(1280px) {
      font-size: 21px !important;
    }
  }

  &-container {
    width: 60%;
    margin-left: 40%;
    background: $yellow;
    padding: 20px;
    line-height: 1.5;
    box-shadow: -10px 10px 8px 0px rgba(42, 34, 4, 0.3);

    @include break(750px) {
      width: 100% !important;
      margin-left: 0 !important;
      padding: 10px !important;
    }

    svg {
      display: inline-block;
      width: 20px;
      margin-right: 10px;
      transform: translateY(3px);
    }

    a {
      color: black;
      text-decoration: none;
      font-variant-numeric: slashed-zero !important;
      line-height: 1.6;
    }

    p,
    a {
      font-size: 21px;
      font-weight: 350;

      @include break(750px) {
        font-size: 16px !important;
      }
    }

    a:hover {
      color: $white;
    }

    &-impressum {
      font-size: 16px !important;
      cursor: pointer;
      color: $white;
      display: block !important;
      margin-top: 40px;

      &:hover {
        color: black;
      }
    }
  }

  .impressum {
    margin-top: 20px;

    &-toggle {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      cursor: pointer;
      position: sticky;
      top: 0;
      background: $yellow;
      padding: 20px 0;

      a {
        @include break(750px) {
          font-size: 14px !important;
        }
      }

      svg {
        &:hover {
          stroke: $white;
        }
      }
    }

    h2 {
      margin-left: 0;
      font-size: 21px;
      margin-bottom: 20px;

      @include break(750px) {
        font-size: 18px !important;
        margin-left: 0 !important;
      }
    }

    p,
    a {
      font-size: 16px;
      max-width: 70ch;
      margin-bottom: 20px;

      @include break(750px) {
        font-size: 14px !important;
      }
    }

    ul {
      margin: 20px 0 20px 20px;
    }
  }
}

.h2-open {
  font-size: 21px !important;
  color: black !important;
  font-weight: 700 !important;

  @include break(750px) {
    font-size: 18px !important;
  }
}
</style>