<template>
  <div class="home" :class="{ pageIsLoaded: $store.state.pageLoaded }">
    <Canvas />
    <Slider />
    <Contact />
  </div>
</template>

<script>
// @ is an alias to /src
import Canvas from '@/components/Canvas.vue'
import Slider from '@/components/Slider.vue'
import Contact from '@/components/Contact.vue'

export default {
  name: 'Home',
  components: {
    Canvas,
    Slider,
    Contact,
  },
  data() {
    return {
      impressumOpen: false,
      imprintData: null,
    }
  },
  methods: {
    async openImprint() {
      let rawData = await fetch(`${process.env.VUE_APP_API_ROUTE}/wp-json/wp/v2/pages/91`)
      this.imprintData = await rawData.json()
      console.log(this.imprintData)
      this.impressumOpen = !this.impressumOpen
    },
  },
}
</script>

<style lang="scss">
.home {
  opacity: 0;
}

.pageIsLoaded {
  opacity: 1 !important;
  transition: opacity 1s ease-in-out;
}

.contact {
  h2 {
    margin-left: 40%;
    font-size: 60px;

    @include break(1280px) {
      font-size: 48px;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
  }

  &-container {
    width: 60%;
    margin-left: 40%;
    background: $yellow;
    padding: 20px;
    line-height: 1.5;
    box-shadow: -10px 10px 8px 0px rgba(42, 34, 4, 0.3);

    svg {
      display: inline-block;
      width: 20px;
      margin-right: 10px;
      transform: translateY(3px);
    }

    a {
      color: black;
      text-decoration: none;
      font-variant-numeric: slashed-zero !important;
      line-height: 1.6;
    }

    p,
    a {
      font-size: 21px;
      font-weight: 350;
    }

    a:hover {
      color: $white;
    }

    video {
      margin-top: 20px;
      width: 100%;
      max-width: 500px;
      display: block;
      margin-bottom: 60px;
    }

    &-impressum {
      font-size: 16px !important;
      cursor: pointer;
      color: $white;
      display: block !important;

      &:hover {
        color: black;
      }
    }
  }

  .impressum {
    margin-top: 20px;

    &-toggle {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      cursor: pointer;
      position: sticky;
      top: 0;
      background: $yellow;
      padding: 20px 0;

      svg {
        &:hover {
          stroke: $white;
        }
      }
    }

    h2 {
      margin-left: 0;
      font-size: 21px;
      margin-bottom: 20px;
    }

    p,
    a {
      font-size: 16px;
      max-width: 70ch;
      margin-bottom: 20px;
    }

    ul {
      margin: 20px 0 20px 20px;
    }
  }
}

.h2-open {
  font-size: 21px !important;
  color: black !important;
  font-weight: 700 !important;
}
</style>
