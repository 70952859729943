<template>
  <!--   <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
  <router-view />
</template>

<script>
export default {
  async mounted() {
    let URL = `${process.env.VUE_APP_API_ROUTE}/wp-json/wp/v2/posts?filter[orderby]=date&order=desc&per_page=100&_embed`
    try {
      let raw = await fetch(URL)
      let data = await raw.json()
      this.$store.commit('getData', data)
    } catch (err) {
      console.log(err)
    }
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: 'ShareTech';
  src: url('./assets/fonts/ShareTechMono-Regular.woff');
}

@font-face {
  font-family: 'Recursive';
  src: url('./assets/fonts/Recursive-ExtraBold.woff');
  font-weight: 800;
}

@font-face {
  font-family: 'Recursive';
  src: url('./assets/fonts/Recursive-Light.woff');
  font-weight: 300;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Recursive';
  scroll-behavior: smooth;
}

::selection {
  color: $yellow;
  background: black;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background: rgb(251, 251, 251);
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
