<template>
  <div>
    <div id="canvas">
      <img class="mobileHero" :style="mobileHeroImage" v-if="!isDesktop" />
      <div class="intro">
        <h1>Lukas Luftläufer</h1>
        <ul class="intro-services">
          <li>Frontend Development</li>
          <li>Webdesign</li>
          <li>Creative Coding</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import P5 from 'p5'

export default {
  name: 'Canvas',
  data() {
    return {
      font: null,
      fontLoaded: false,
      designLength: 0,
      codeLength: 0,
      steps: 16,
      noiseFactor: 2,
      mobileHeroImage: {
        backgroundImage: `url(${require('@/assets/images/mobileHero.jpg')})`,
      },
    }
  },
  computed: {
    isDesktop() {
      return window.innerWidth >= 750
    },
  },

  mounted() {
    const script = (c) => {
      c.resize = () => {
        c.createCanvas(window.innerWidth, window.innerHeight, c.P2D)
        console.log('resized')
      }

      // c.preload = () => {
      //   this.font = c.loadFont(
      //     require("@/assets/fonts/ShareTechMono-Regular.woff")
      //   );
      // };

      c.setup = () => {
        //c.textFont(this.font);
        c.createCanvas(window.innerWidth, window.innerHeight, c.P2D)
        let noiseSeed = 0
        c.noiseSeed(noiseSeed)
      }
      c.draw = () => {
        c.background(251, 251, 251)

        this.steps = 16

        let design = 'DESIGN'
        let code = 'CODE'
        this.designLength = c.textWidth(design)
        this.codeLength = c.textWidth(code)
        c.textSize(this.steps)

        /* offset left side by length of word */
        c.translate(-this.designLength, 0)

        c.push()

        let rowHeight = c.int(c.height / this.steps)
        let mouseAction = (c.mouseX + c.mouseY) * 0.001

        for (let y = 0; y < rowHeight * this.steps; y += this.steps) {
          c.translate(0, this.steps)

          let rowLength = c.map(c.noise(0, 0, c.frameCount * 0.0015), 0, 1, 0, c.width / 8) - this.steps
          let designMove = c.map(c.noise(mouseAction, 0, (y + c.frameCount) * 0.0015), 0, 1, -this.steps * this.noiseFactor, this.steps * this.noiseFactor)
          let codeMove = c.map(c.noise(mouseAction, 0, (y + c.frameCount) * 0.0015), 0, 1, -this.steps * this.noiseFactor, this.steps * this.noiseFactor)

          /* DESIGN */
          c.push()
          c.translate(-this.designLength, 0)
          for (let x = 0; x < rowLength; x += this.steps / 2) {
            c.translate(this.designLength + designMove + this.steps / 2, 0)
            let designColor = c.map(x, 0, rowLength, 0, 251)
            c.fill(designColor)
            c.text(design, 0, 0)
          }
          c.pop()

          /* CODE */
          c.push()

          /* offset right side by length of word */
          c.translate(c.width + this.codeLength, 0)
          for (let x = 0; x < rowLength; x += this.steps / 2) {
            c.translate(-this.codeLength + codeMove - this.steps / 2, 0)
            let codeColor = c.map(x, 0, rowLength, 0, 251)
            c.fill(codeColor)
            c.text(code, 0, 0)
          }
          c.pop()
        }
        c.pop()
      }
    }
    // Attach the canvas to the div
    if (this.isDesktop) {
      const p5canvas = new P5(script, 'canvas')
    }
  },
}
</script>

<style scoped lang="scss">
#canvas {
  overflow-x: hidden;
  position: relative;

  @include break(750px) {
    height: 100vh;
    width: 100%;
  }

  .mobileHero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  &:after {
    position: absolute;
    content: '';
    background-image: linear-gradient(transparent 50%, $white);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include break(750px) {
      background-image: linear-gradient(transparent 85%, $white);
    }
  }

  .intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // background: rgba(255, 255, 255, 0.3);
    // padding: 40px;
    border-radius: 20px;
    animation: fadeIn 2s 1s ease-in-out forwards;
    opacity: 0;
    text-align: center;

    @include break(750px) {
      animation: fadeIn 1s ease-in-out forwards;
    }

    h1 {
      text-transform: uppercase;
      font-size: 60px;
      line-height: 1;
      margin-bottom: 20px;
      color: $yellow;
      text-shadow: 0px 2px 2px black;

      @include break(500px) {
        font-size: 48px;
        line-height: 1.2;
      }
    }

    ul {
      list-style: none;
      li {
        font-size: 32px;
        font-weight: 300;
        line-height: 1.2;

        @include break(750px) {
          font-size: 24px;
        }
      }
    }
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  canvas {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}
</style>
