<template>
  <div class="slides">
    <h2 class="slidesHeading" ref="slidesHeading">PROJEKTE</h2>
    <swiper
      v-if="getWebsites.length > 0"
      :slides-per-view="1"
      :space-between="20"
      :speed="500"
      :loop="true"
      :lazy="true"
      @slideChange="onSlideChange"
      @init="hideButton"
      navigation
      :breakpoints="{
        '750': {
          slidesPerView: 4,
          spaceBetween: 40,
          speed: 150,
        },
      }"
    >
      <swiper-slide v-for="(website, index) in getWebsites" :key="website">
        <a :href="website._embedded['wp:term'][1][0].name" target="_blank">
          <img :data-src="getFeaturedImages[index]" alt="" class="swiper-lazy" />
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </a>
        <div class="slider-card">
          <h2>{{ website.title.rendered }}</h2>
          <div class="slider-projectType" v-html="website.excerpt.rendered"></div>
          <div class="slider-year">
            {{ website.date.substring(0, 4) }}
          </div>
          <div class="slide-content">
            <div v-html="website.content.rendered"></div>
            <div class="slider-tags">
              <div class="slider-tag" v-for="tag in website._embedded['wp:term'][0]" :key="tag">{{ tag.name }}</div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components

//import { mapState } from 'vuex'

import { Swiper, SwiperSlide } from 'swiper/vue'
import SwiperCore, { Navigation, Lazy } from 'swiper/core'
SwiperCore.use([Lazy, Navigation])

import 'swiper/swiper.scss'
import 'swiper/modules/lazy/lazy.scss'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      activeSlide: null,
      hasBeenSlided: false,
    }
  },
  computed: {
    getWebsites() {
      return this.$store.state.websites
    },
    getFeaturedImages() {
      return this.$store.state.featuredImages
    },
  },
  methods: {
    onSlideChange() {
      this.hasBeenSlided = true
      if (this.hasBeenSlided) {
        document.querySelector('.swiper-button-prev').style.visibility = 'visible'
        this.$refs.slidesHeading.classList.add('slidesHeading--moved')
      }
    },
    hideButton() {
      document.querySelector('.swiper-button-prev').style.visibility = 'hidden'
      this.$refs.slidesHeading.classList.remove('slidesHeading--moved')
    },
  },
  updated() {
    /* fade-in css class for Home.vue */
    this.$store.commit('loadPage')
  },
}
</script>

<style lang="scss">
.slidesHeading {
  font-size: 60px;
  margin-left: calc(60% - 270px);

  @include break(1280px) {
    font-size: 40px;
    margin-left: calc(60% - 182px);
  }

  @include break(750px) {
    margin-left: 10px;
    transform: translateY(50px);
    font-size: 36px;
    transition: transform 0.5s ease-out;
  }

  &--moved {
    transform: translateY(0);
  }
}

.slides {
  width: 100%;
  padding: 100px 0 200px 0;
  overflow: hidden;

  @include break(750px) {
    padding: 40px 0 75px 0;
  }
}

.swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
  position: relative;
  //width: calc(30% + 20px + 10px) !important;
  width: 30%;

  @include break(750px) {
    min-width: 50%;
    padding-right: 10px;
  }

  &:before {
    height: 100%;
    width: 16px;
    content: '';
    background: $yellow;
    box-shadow: 10px 11px 8px 0px rgba(42, 34, 4, 0.3);
    right: 0;
    position: absolute;
    z-index: 0;

    @include break(750px) {
      display: none;
    }
  }

  &:after {
    content: '\2192';
    animation: blink 1s 7;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
}

.swiper-button-prev {
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  //width: calc(30% - 30px) !important;
  min-width: 30%;

  @include break(750px) {
    min-width: 50%;
  }

  &:after {
    padding-left: 20px;
    content: '\2190';

    @include break(750px) {
      padding-left: 10px !important;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  font-size: 3rem;
  //width: 30%;
  height: 80px;
  cursor: pointer;
  background: $yellow;
  color: $white;

  @include break(750px) {
    font-size: 2.4rem;
    height: 60px;
  }

  &:hover {
    color: black;
  }
}

.swiper-slide-active {
  display: flex;
  max-width: 60% !important;
  width: 60% !important;
  transition: all 0.5s ease-out !important;
  background: $yellow;
  padding: 0 20px 0 0;
  opacity: 1 !important;
  box-shadow: 10px 10px 8px 0px rgba(42, 34, 4, 0.3);
  filter: none !important;
  height: 500px !important;

  @include break(750px) {
    max-width: 100% !important;
    width: 100% !important;
    flex-direction: column-reverse;
    height: initial !important;
    max-height: initial !important;
    padding: 0;
  }

  .slider-card {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    max-width: calc(30% + 40px);

    @include break(750px) {
      max-width: 100%;
      margin-left: 10px;
      padding-right: 10px;
    }
  }

  h2 {
    font-weight: 750;
    letter-spacing: 0.5px;
    margin-top: -5px;
    font-size: 32px;

    @include break(750px) {
      font-size: 24px;
      word-break: break-word;
      margin-top: 10px;
    }
  }

  .slider-projectType p {
    color: $white;

    @include break(750px) {
      font-size: 18px !important;
    }
  }

  .slider-year {
    display: block !important;
    margin-bottom: 5px;
    font-weight: 800;

    @include break(750px) {
      font-size: 14px !important;
    }
  }

  a {
    display: block;
    width: 51% !important;
    position: relative;

    @include break(750px) {
      width: 100% !important;
    }

    &:hover::after {
      opacity: 0.6;
    }

    &::after {
      transition: opacity 0.25s ease-in-out;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(-45deg, $yellow, transparent);
      opacity: 0;
      mix-blend-mode: multiply;
    }

    img {
      transition: all 0.5s ease-out !important;
      filter: none !important;
      margin-bottom: 0 !important;
      height: 500px !important;
      //box-shadow: 10px -10px 20px rgba(0, 0, 0, 0.05);

      @include break(750px) {
        height: 300px !important;
        display: block;
      }
    }
  }

  .slide-content {
    display: block !important;

    div p {
      font-weight: 350;
      max-width: 400px;
      line-height: 1.4;
      font-size: 18px;

      @include break(750px) {
        font-size: 16px;
      }
    }
  }

  .slider-tags {
    display: flex !important;
    flex-wrap: wrap;
    max-width: 250px;
    margin-top: 20px;

    @include break(750px) {
      max-width: 75%;
      margin-bottom: 20px;
    }

    .slider-tag {
      background: black;
      color: white;
      border-radius: 20px;
      padding: 5px 10px;
      white-space: nowrap;
      font-size: 11px;
      margin: 0 5px 5px 0;

      @include break(750px) {
        font-size: 10px;
        margin: 0 2px 2px 0;
      }
    }
  }
}

.swiper-slide {
  width: 200px;
  height: calc(100vh - 400px);
  max-height: 600px;
  opacity: 0.5;
  transition: all 0.5s ease-out !important;
  //filter: blur(2px);

  img {
    transition: all 0.5s ease-out !important;
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-right: 0;
    filter: grayscale(0.9);
    pointer-events: none;
    margin-bottom: 10px;

    @include break(750px) {
      height: 300px !important;
    }
  }

  .slide-content {
    display: none;
  }

  .slider-year {
    display: none;
  }

  .slider-projectType p {
    font-size: 21px;
    margin-bottom: 20px;
    font-family: 'ShareTech';
  }

  .slider-tags {
    display: none;
  }
}
</style>