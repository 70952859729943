import { createStore } from 'vuex'

export default createStore({
  state: {
    websites: [],
    featuredImages: [],
    pageLoaded: false //fade in toggle CSS class on Home.vue
  },
  mutations: {
    getData(state, data) {
      // fill state
      state.websites = data
      //console.log(state.websites)

      // fill featured images
      for (let i = 0; i < state.websites.length; i++) {
        state.featuredImages.push(state.websites[i]._embedded['wp:featuredmedia'][0].source_url)
      }
    },
    loadPage(state) {
      state.pageLoaded = true
    }
  },
  actions: {
  },
  modules: {
  }
})
